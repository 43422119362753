/* eslint import/prefer-default-export: 0 */
import React from 'react'
import {
  take,
  call,
  put,
  fork,
  cancel
} from 'redux-saga/effects'
import { addModal } from 'modals/actions'
import { warningModal } from 'modals/sagas'
import ReportViewer from 'components/ReportViewer'

import { dataURItoBlob } from 'utils'
import FileSaver from 'file-saver'
import {  REMOVE_MODAL } from 'modals/constants'
import PrintDocumentModal from '.'
import SaveDocument from './components/SaveDocument'
import * as actions from './actions'
import * as CONSTANTS from './constants'
import * as api from './api'

/* for callFetchBlob */
// import { tokenSelector } from 'auth/selectors'
// import { showAccessOverrideModal } from 'access/sagas'
// import { overrideListener } from 'access/callFetch'
// import fetchProcessBlob from './fetchProcessBlob'
/* end imports for callFetchBlob */

let printDocumentModalId
let tasks

export function* showPrintDocumentModalProcess(
  form,
  data,
  parentModalCb,
  closeParentModal = false
) {
  const { allowSend, allowView, dataId, description, isExportOnly } = data // eslint-disable-line

  /* ensure any potentially hanging listeners are destroyed and then re-initiate them */
  yield fork(destroyListeners)
  yield fork(printDocumentModalSagas, form)

  /* close the parent modal if needed */
  if (closeParentModal && parentModalCb) {
    parentModalCb()
  }

  const cancelActionButton = {
    primary: true,
    title: 'Cancel',
    async clickEvent(args, formState, cb) {
      try {
        await this.props.dispatch(actions.cancelPrintDocument(form, { dataId }))
      } finally {
        cb()
      }
    }
  }

  let modalActions = [
    {
      primary: true,
      title: 'Print',
      async clickEvent(args, formState, cb) {
        const { printerId, collate, copies } = this.state
        try {
          await this.props.dispatch(
            actions.printDocument(form, { dataId, printerId, copies, collate })
          )
        } finally {
          cb()
        }
      }
      // disabled: formState => true
    },
    {
      primary: true,
      title: 'View',
      async clickEvent(args, formState, cb) {
        try {
          await this.props.dispatch(actions.viewDocument(form, { dataId }))
        } finally {
          cb()
        }
      }
    },
    {
      primary: true,
      title: 'Send',
      // disabled: formState => true,
      async clickEvent(args, formState, cb) {
        try {
          await this.props.dispatch(actions.sendDocument(form, { dataId }))
        } finally {
          cb()
        }
      }
    },
    cancelActionButton
  ]

  modalActions = modalActions.reduce((acc, next) => {
    if (next.title === 'Print' || next.title === 'Cancel') {
      acc = acc.concat(next)
    }
    if (allowView && next.title === 'View') {
      acc = acc.concat(next)
    }
    if (allowSend && next.title === 'Send') {
      acc = acc.concat(next)
    }
    return acc
  }, [])

  const options = {
    component: isExportOnly ? SaveDocument : PrintDocumentModal,
    options: {
      data: {
        actions: isExportOnly ? [cancelActionButton] : modalActions,
        responseData: { ...data, form }
      },
      title: isExportOnly ? 'Save Document' : description,
      maxHeight: 800,
      width: isExportOnly ? 300 : 550
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
  printDocumentModalId = modal.payload.id
  return printDocumentModalId
}

export function* viewDocumentListener(formListener) {
  let task
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.VIEW_DOCUMENT.TRY)

    if (formListener === form) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(viewDocumentProcess, payload, form)
    }
  }
}

export function* viewDocumentProcess(payload, form) {
  yield put({
    type: CONSTANTS.VIEW_DOCUMENT.REQUEST,
    meta: { apiRequest: true, form }
  })

  const { response, error } = yield call(api.viewDocument, {
    dataId: payload.dataId
  })

  if (response) {
    yield put({
      type: CONSTANTS.VIEW_DOCUMENT.SUCCESS,
      meta: { form },
      payload: response
    })

    const modalOpts = {
      component: ReportViewer,
      options: {
        data: {
          actions: [
            {
              primary: true,
              title: 'Exit',
              async clickEvent(args, formState, cb) {
                try {
                  /* Truck Manifest is the ONLY screen that needs this hook */
                  await this.props.dispatch(
                    actions.closeReportViewer(form, { dataId: payload.dataId })
                  )
                } finally {
                  cb()
                }
              }
            }
          ],
          pdfData: response.document
        },
        fullScreen: true,
        title: 'Report Viewer',
        marginTop: '0px',
        maxHeight: '95%',
        maxWidth: '95%',
        width: '95%'
      }
    }

    /* for debugging purposes ONLY ONLY */
    /* uncomment this little script to open the raw PDF in Chrome */
    /* if it opens in Chrome, it SHOULD open in the Report Viewer as well */
    /* if not, we have a data issue */
    // const pdfWindow = window.open('')
    // pdfWindow.document.write(
    //   `<iframe width='100%' height='100%' src='${response.document}'></iframe>`
    // )

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    yield fork(destroyListeners)
  } else {
    yield put({
      type: CONSTANTS.VIEW_DOCUMENT.FAILURE,
      meta: { form },
      payload: error
    })

    if (error && error.message && error.title) {
      yield call(warningModal, error.message, error.title)
    }
    yield fork(destroyListeners)
  }
}

export function* printDocumentProcess(payload, form) {
  const { dataId, printerId, copies, collate } = payload

  yield put({
    type: CONSTANTS.PRINT_DOCUMENT.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.printDocument, {
    dataId,
    printerId,
    copies,
    collate
  })

  if (response) {
    yield put({
      type: CONSTANTS.PRINT_DOCUMENT.SUCCESS,
      payload: response,
      meta: { form }
    })
    yield put(actions.printDocumentRoutineCompleted(form))
    yield fork(destroyListeners)
  } else {
    yield put({
      type: CONSTANTS.PRINT_DOCUMENT.FAILURE,
      payload: error,
      meta: { form }
    })

    if (error?.message && error?.status !== 496) {
      /* if its a 496, it gets handled by the framework */
      yield call(warningModal, error.message, 'Error!')
    }
    yield put(actions.printDocumentRoutineCompleted(form))
    yield fork(destroyListeners)
  }
}

export function* displayApiError(error) {
  if (error && error.message && error.title) {
    yield call(warningModal, error.message, error.title)
  }
}

export function* printDocumentListener(formListener) {
  let task
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.PRINT_DOCUMENT.TRY)

    if (formListener === form) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(printDocumentProcess, payload, form)
    }
  }
}

export function* saveDocumentProcess(payload, form) {
  const { dataId, filename, fileType } = payload

  yield put({
    type: CONSTANTS.SAVE_DOCUMENT.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.saveDocument, {
    dataId,
    filename,
    fileType
  })

  if (response) {
    const documentName = `${filename || dataId}.${fileType}`
    if (fileType === 'pdf') {
      FileSaver.saveAs(dataURItoBlob(response.document), documentName)
    } else {
      const fileTypes = {
        csv: 'text/csv;charset=utf-8',
        txt: 'text/plain;charset=utf-8',
        xls: 'application/vnd.ms-excel'
      }

      const fileData = new Blob([response], { type: fileTypes[fileType] })

      FileSaver.saveAs(fileData, documentName)
    }

    if (printDocumentModalId) {
      yield put({
        payload: { id: printDocumentModalId },
        meta: { form, modal: true },
        type: REMOVE_MODAL
      })
    }

    yield put({
      type: CONSTANTS.SAVE_DOCUMENT.SUCCESS,
      payload: { dataId },
      meta: { form }
    })
    yield put(actions.printDocumentRoutineCompleted(form))
    yield fork(destroyListeners)
  } else {
    yield put({
      type: CONSTANTS.SAVE_DOCUMENT.FAILURE,
      meta: { form },
      payload: error
    })

    /*
      this means that some bizarre API
      like Customer Order Form for example
      has sent Print Document interface data
      for a document that actually does not exist.
      In that case, the message comes back screwy anyways
    */
    // console.log(error)
    // debugger
    if (error && error.message) {
      /* some of the API error messages are getting mishandled somewhere along the way */
      const displayMessage = error.message.match(/Cannot create property/gi)
        ? 'Document contains no data'
        : error.message
      yield call(warningModal, displayMessage, 'Attention!')
    }

    if (printDocumentModalId) {
      yield put({
        payload: { id: printDocumentModalId },
        meta: { form, modal: true },
        type: REMOVE_MODAL
      })
    }

    yield fork(destroyListeners)
  }
}

export function* saveDocumentListener(formListener) {
  let task
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.SAVE_DOCUMENT.TRY)

    if (formListener === form) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(saveDocumentProcess, payload, form)
    }
  }
}

export function* cancelPrintDocumentProcess(dataId, form) {
  yield put({
    type: CONSTANTS.CANCEL_PRINT_DOCUMENT.REQUEST,
    meta: { form }
  })

  const { response, error } = yield call(api.cancelPrintDocument, {
    dataId
  })

  if (response) {
    yield put({
      type: CONSTANTS.CANCEL_PRINT_DOCUMENT.SUCCESS,
      payload: response,
      meta: { form }
    })
    yield put(actions.printDocumentRoutineCompleted(form, true))
    yield fork(destroyListeners)
  } else {
    yield put({
      type: CONSTANTS.CANCEL_PRINT_DOCUMENT.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* cancelPrintDocumentListener(formListener) {
  let task
  while (true) {
    const {
      payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_PRINT_DOCUMENT.TRY)

    if (formListener === form) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(cancelPrintDocumentProcess, dataId, form)
    }
  }
}

export function* getAllPrintersProcess(allPrinters, dataId, form) {
  yield put({
    type: CONSTANTS.GET_ALL_PRINTERS.REQUEST,
    meta: { form }
  })

  const { response, error } = yield call(api.getAllPrinters, {
    allPrinters,
    dataId
  })

  if (response) {
    yield put({
      type: CONSTANTS.GET_ALL_PRINTERS.SUCCESS,
      payload: response,
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.GET_ALL_PRINTERS.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* getAllPrintersListener(formListener) {
  while (true) {
    const {
      payload: { allPrinters, dataId },
      meta: { form }
    } = yield take(CONSTANTS.GET_ALL_PRINTERS.TRY)

    if (formListener === form) {
      yield fork(getAllPrintersProcess, allPrinters, dataId, form)
    }
  }
}

export function* setSessionDefaultProcess(payload, form) {
  const { dataId, printerId, sessionDefault } = payload

  yield put({
    type: CONSTANTS.SET_SESSION_DEFAULT.REQUEST,
    meta: { form }
  })

  const { response, error } = yield call(api.setSessionDefault, {
    dataId,
    printerId,
    sessionDefault
  })

  if (response) {
    yield put({
      type: CONSTANTS.SET_SESSION_DEFAULT.SUCCESS,
      payload: response,
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.SET_SESSION_DEFAULT.FAILURE,
      payload: error,
      meta: { form }
    })

    yield fork(displayValidationErrors, error)
  }
}

export function* displayValidationErrors(error) {
  if (
    error &&
    error.validationErrors &&
    Array.isArray(error.validationErrors)
  ) {
    const errorMessageString = error.validationErrors.reduce((acc, next) => {
      acc = acc.concat(`${next.property}: ${next.message}\n`)
      return acc
    }, '')
    yield call(warningModal, errorMessageString, 'Error!')
  }
}

export function* setSessionDefaultListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.SET_SESSION_DEFAULT.TRY)

    if (formListener === form) {
      yield fork(setSessionDefaultProcess, payload, form)
    }
  }
}

export function* destroyListeners() {
  if (tasks) {
    for (let i = 0, len = tasks.length; i < len; i++) {
      yield cancel(tasks[i])
    }
  }
}

export default function* printDocumentModalSagas(formListener) {
  tasks = [
    yield fork(viewDocumentListener, formListener),
    yield fork(printDocumentListener, formListener),
    yield fork(saveDocumentListener, formListener),
    yield fork(cancelPrintDocumentListener, formListener),
    yield fork(getAllPrintersListener, formListener),
    yield fork(setSessionDefaultListener, formListener)
  ]
}
